html,body{
  box-sizing: border-box;
  /* overflow-x: hidden; */
  margin: 0;
  padding: 0;
  
}

.Homeimg{
  animation: bounce 6s infinite;

}

section{
  overflow-x: hidden;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(-8%);
    animation-timing-function: cubic-bezier(1, 1, 1, 1);
  }
  50% {
    transform: translateY(2%);
    animation-timing-function: cubic-bezier(1, 1, 1, 1);
  }
}


::-webkit-search-decoration,
::-webkit-search-cancel-button,
::-webkit-search-results-button,
::-webkit-search-results-decoration { 
  display: none; 
}

.blurcontainer{
  position: fixed;
}

.number-input:focus {
  outline:none !important;
}

.number-input:focus {

  outline:none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.scrollbar::-webkit-scrollbar {
  display: none;
}


.hamburger {
  cursor: pointer;
}

.hamburger input {
  display: none;
}

.hamburger svg {
  /* The size of the SVG defines the overall size */
  height: 2em;
  /* Define the transition for transforming the SVG */
  transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line {
  fill: none;
  stroke: white;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 3;
  /* Define the transition for transforming the Stroke */
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
              stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line-top-bottom {
  stroke-dasharray: 12 63;
}

.btn-special {
  --color: #560bad;
  font-family: inherit;
  display: inline-block;
  width: 9em;
  height: 2.6em;
  line-height: 2.5em;
  margin: 20px;
  position: relative;
  overflow: hidden;
  border: 2px solid var(--color);
  transition: color .5s;
  z-index: 1;
  font-size: 17px;
  border-radius: 6px;
  font-weight: 500;
  color: var(--color);
 }
 
 .btn-special:before {
  content: "";
  position: absolute;
  z-index: -1;
  background: var(--color);
  height: 150px;
  width: 200px;
  border-radius: 50%;
 }
 
 .btn-special:hover {
  color: #fff;
 }
 
 .btn-special:before {
  top: 100%;
  left: 100%;
  transition: all .7s;
 }
 
 .btn-special:hover:before {
  top: -30px;
  left: -30px;
 }
 
 .btn-special:active:before {
  background: #3a0ca3;
  transition: background 0s;
 }


 .btn-send {
  font-family: inherit;
  font-size: 20px;
  background: royalblue;
  color: white;
  padding: 0.7em 1em;
  padding-left: 0.9em;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 16px;
  overflow: hidden;
  transition: all 0.2s;
  cursor: pointer;
}

.btn-send span {
  display: block;
  margin-left: 0.3em;
  transition: all 0.3s ease-in-out;
}

.btn-send svg {
  display: block;
  transform-origin: center center;
  transition: transform 0.3s ease-in-out;
}

.btn-send:hover .svg-wrapper {
  animation: fly-1 0.6s ease-in-out infinite alternate;
}

.btn-send:hover svg {
  transform: translateX(1.2em) rotate(45deg) scale(1.1);
}

.btn-send:hover span {
  transform: translateX(5em);
}

.btn-send:active {
  transform: scale(0.95);
}

@keyframes fly-1 {
  from {
    transform: translateY(0.1em);
  }

  to {
    transform: translateY(-0.1em);
  }
}

.load {
  width: 2em;
  transform-origin: center;
  animation: rotate4 2s linear infinite;
 }
 
 circle {
  fill: none;
  stroke: hsl(0, 0%, 100%);
  stroke-width: 3;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash4 1.5s ease-in-out infinite;
 }
 
 @keyframes rotate4 {
  100% {
   transform: rotate(360deg);
  }
 }
 
 @keyframes dash4 {
  0% {
   stroke-dasharray: 1, 200;
   stroke-dashoffset: 0;
  }
 
  50% {
   stroke-dasharray: 90, 200;
   stroke-dashoffset: -35px;
  }
 
  100% {
   stroke-dashoffset: -125px;
  }
 }


 .overflow-x-auto {
  overflow-x: auto;
  white-space: nowrap; /* Prevent wrapping */
}

/* Inner grid container with fixed width */
.carTemplate {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(2, auto);
  gap: 1rem; /* Adjust gap as needed */
  width: max-content; /* Ensure the grid takes width based on content */
}

 .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-button-prev::before, 
.swiper-button-next::before {
  font-size: 16px !important;  /* Add !important to force the size change */
  color: #000; /* Optional: Change the icon color */
}

/* Alternatively, target SVG directly if icons are SVGs */
.swiper-button-prev svg, 
.swiper-button-next svg {
  width: 16px;  /* Adjust the width of the SVG icon */
  height: 16px; /* Adjust the height of the SVG icon */
  fill: #000;   /* Optional: Change the SVG fill color */
}

/* Adjust the size of the navigation button container itself */
.swiper-button-prev, 
.swiper-button-next {
  width: 30px !important;  /* Adjust button container width */
  height: 30px !important; /* Adjust button container height */
}

.loader {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite
}
.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 3px solid #FFF;
  animation: prixClipFix 2s linear infinite ;
}

@keyframes rotate {
  100%   {transform: rotate(360deg)}
}

@keyframes prixClipFix {
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
    100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
}

.sticky-element{
  position: -webkit-sticky; /* For Safari */
  position: sticky;}



  /* full image css  */
  /* .galleryWrap {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    justify-content: center;
    max-width: 620px;
    margin: 0 auto;
  } */
  
  .galleryWrap .single {
    width: 200px;
    cursor: pointer;
  }
  
  .galleryWrap .single img {
    max-width: 100%;
  }
  
  .galleryWrap .single img:hover {
    transform: scale(1.02);
  }
  
  .sliderWrap {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  
  .sliderWrap .btnClose, .sliderWrap .btnPrev, .sliderWrap .btnNext {
    position: fixed;
    cursor: pointer;
    opacity: 0.6;
    color: #fff;
    z-index: 9999;
  }
  
  .btnNext:hover, .btnPrev:hover, .btnClose:hover {
    opacity: 1;
  }
  
  .sliderWrap .btnClose {
    top: 40px;
    right: 40px;
  }
  
  .sliderWrap .btnPrev {
    top: 50%;
    transform: translateY(-50%);
    left: 40px;
  }
  
  .sliderWrap .btnNext {
    top: 50%;
    transform: translateY(-50%);
    right: 40px;
  }
  
  .fullScreenImage {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .fullScreenImage img {
    max-width: 100%;
    max-height: 100%;
    pointer-events: none;
    -webkit-user-select: none;
      /* Safari */
      -ms-user-select: none;
      /* IE 10 and IE 11 */
      user-select: none;
      /* Standard syntax */
    }
    
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    
    /* Hide spinner controls in Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }


    .truncate-multi-line {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3; 
      overflow: hidden;
      text-overflow: ellipsis;
    }
    

    body::-webkit-scrollbar {
      width: 8px;  
    }
 
    
    body::-webkit-scrollbar-thumb {
      border-radius: 20px;   
      background-color:  rgb(3, 97, 255)
    
    }

    .notifications-container::-webkit-scrollbar {
      width: 10px;       
      padding: 2px;       
  
  }
  
  .notifications-container::-webkit-scrollbar-thumb {
      border-radius: 20px;       
      background-color:  rgb(3, 97, 255)
  }


  .swiper-button-next::after, .swiper-button-prev::after {
    content: "";
}

:root {
  --swiper-navigation-color: white;
  --swiper-pagination-color:white;
}




